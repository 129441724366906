import {t} from '@lingui/macro'
import {
    Avatar,
    Badge,
    createStyles,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Theme,
} from '@material-ui/core'
import {useNotification} from 'contexts/notification'
import {useGetNotificationsCountQuery} from 'generated/graphql'
import {BellIcon} from 'icons'
import {FC} from 'react'

export type NotificationBellProps = {
    asListItem?: boolean
    color?: string
    drawerExpanded?: boolean
}

export const NotificationBell: FC<NotificationBellProps> = ({asListItem, color, drawerExpanded}) => {
    const classes = useStyles()
    const {open, toggleNotifications} = useNotification()
    const {data} = useGetNotificationsCountQuery({fetchPolicy: 'network-only', pollInterval: 1000 * 60})

    if (asListItem) {
        return (
            <ListItem onClick={toggleNotifications} button={true} component='a' selected={open}>
                <ListItemIcon>
                    <Badge color='error' variant='dot' badgeContent={data?.notifications.notReadCount} overlap='circle'>
                        <BellIcon />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={t`Notifications`} className={classes.label} />
                {!!data?.notifications.notReadCount && drawerExpanded === true && (
                    <ListItemSecondaryAction>
                        <Avatar
                            className={`${classes.avatar} ${open && classes.avatarSelected}`}
                            variant='rounded'
                            color='secondary'>
                            {data?.notifications.notReadCount}
                        </Avatar>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
        )
    }

    return (
        <IconButton color='primary' onClick={toggleNotifications}>
            <Badge color='error' variant='dot' badgeContent={data?.notifications.notReadCount} overlap='circle'>
                <BellIcon htmlColor={color} />
            </Badge>
        </IconButton>
    )
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(3),
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        fontSize: '.75rem',
    },
    avatarSelected: {
        backgroundColor: theme.palette.primary.main,
    },
    label: {
        paddingLeft: theme.spacing(1.5),
    },
}))
