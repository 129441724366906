import {t, Trans} from '@lingui/macro'
import {
    Avatar,
    Box,
    Button,
    createStyles,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Tab,
    Tabs,
    Theme,
} from '@material-ui/core'
import {NotificationsTab} from 'components/notifications/notification-tab'
import {
    GetNotificationsCountDocument,
    GetNotificationsDocument,
    useGetNotificationsCountQuery,
    useMarkAllNotificationsAsReadMutation,
} from 'generated/graphql'
import {FC, useState} from 'react'

enum TABS {
    ALL,
    NOT_READ,
}

export const NotificationSidebar: FC<{toggleSideBar: () => void}> = ({toggleSideBar}) => {
    const classes = useStyles()
    const {data: {notifications: {notReadCount = undefined} = {}} = {}, refetch} = useGetNotificationsCountQuery({fetchPolicy: 'cache-only'})
    const [markAllNotificationsAsRead] = useMarkAllNotificationsAsReadMutation({
        refetchQueries: [
            {query: GetNotificationsCountDocument},
            {query: GetNotificationsDocument},
        ],
    })
    const [value, setValue] = useState<TABS>(TABS.ALL)

    return (
        <>
            <List className={classes.list}>
                <ListItem>
                    <ListItemText primary={<Trans>Notifications</Trans>} primaryTypographyProps={{variant: 'h4'}} />
                    <ListItemText
                        primary={(
                            <Button
                                onClick={() => markAllNotificationsAsRead().then(() => refetch())}
                                color='primary'
                                variant='text'>
                                <Trans>Mark all as read</Trans>
                            </Button>
                        )}
                    />
                </ListItem>
                <Tabs
                    value={value}
                    onChange={(_, v) => setValue(v)}
                    centered={true}>
                    <Tab value={TABS.ALL} label={t`All notifications`} classes={{root: classes.tabRoot}} />
                    <Tab
                        disabled={notReadCount === 0}
                        value={TABS.NOT_READ}
                        label={(
                            <Box display='flex' gridColumnGap={8} alignItems='center'>
                                <Trans>Not read</Trans>
                                <Avatar className={classes.avatar} variant='rounded' color='secondary'>{notReadCount}</Avatar>
                            </Box>
                        )}
                        classes={{root: classes.tabRoot}}
                    />
                </Tabs>
            </List>
            {value === TABS.ALL && <NotificationsTab toggleSideBar={toggleSideBar} />}
            {value === TABS.NOT_READ && <NotificationsTab unreadNotifications={true} toggleSideBar={toggleSideBar} />}
        </>
    )
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    list: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
    },
    tabRoot: {
        textTransform: 'none',
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(3),
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        fontSize: '.75rem',
    },
    endBox: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        alignSelf: 'center',
        width: '100%',
        padding: theme.spacing(2),
    },
    label: {
        color: 'white',
    },
}))

