import {Collapse, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon} from '@material-ui/icons'
import Link from 'next/link'
import {useRouter} from 'next/router'
import React, {FC, useState} from 'react'

interface AppMenuLinkProps {
    href: string
    label?: string
    icon: React.ReactElement
    isExternalLink?: boolean
}

export const AppMenuLink: FC<AppMenuLinkProps> = ({href, label, icon, isExternalLink, children}) => {
    const classes = useStyles()
    const router = useRouter()

    isExternalLink = href.startsWith('https://') || href.startsWith('http://') || href.startsWith('mailto:') || isExternalLink
    const isExpandable = Boolean(children)
    const [expanded, setExpanded] = useState<boolean>(router.pathname.startsWith(href))

    const linkIsActive = !isExpandable && router.pathname === href

    const button = (
        <ListItem
            button={true}
            component={isExpandable ? 'button' : 'a'}
            selected={linkIsActive}
            onClick={() => isExpandable && setExpanded(!expanded)}>
            <ListItemIcon>{icon}</ListItemIcon>
            {label && <ListItemText primary={label} className={classes.label} />}
            {isExpandable && (expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
        </ListItem>
    )

    if (isExternalLink) {
        return React.cloneElement(button, {href, target: '_blank'})
    }

    return (
        <>
            {isExpandable ? button : <Link href={href} passHref={true}>{button}</Link>}
            {isExpandable && (
                <Collapse in={expanded}>
                    <List disablePadding={true} className={classes.children}>
                        {children}
                    </List>
                </Collapse>
            )}
        </>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    children: {
        paddingLeft: theme.spacing(2),
    },
    label: {
        paddingLeft: theme.spacing(1.5),
    },
}))
