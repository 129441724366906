import {Trans} from '@lingui/macro'
import {
    Box,
    Button,
    CircularProgress,
    createStyles,
    Grid,
    List,
    ListItem,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core'
import {GraphQLErrors} from 'components/graphql-errors'
import {NotificationItem} from 'components/notifications/notification-item'
import dayjs from 'dayjs'
import {
    GetNotificationsCountDocument,
    GetNotificationsDocument,
    NotificationFragment,
    useDeleteNotificationMutation,
    useGetNotificationsLazyQuery,
    useMarkAllNotificationsAsReadMutation,
    useMarkNotificationAsReadMutation,
} from 'generated/graphql'
import {useRouter} from 'next/router'
import {FC, useEffect, useState} from 'react'

type NotificationsTabProps = {
    unreadNotifications?: boolean
    toggleSideBar: () => void
}

export const NotificationsTab: FC<NotificationsTabProps> = ({unreadNotifications, toggleSideBar}) => {
    const classes = useStyles()
    const {
        handlerMarkNotificationAsRead,
        handlerDeleteNofification,
        handlerRedirect,
        error,
        loading,
        refetch,
        firstLoad,
        allNotificationsLoaded,
        loadMore,
        notifications,
    } = useNotifications({unreadNotifications})

    if (error) {
        return (
            <List className={classes.list}>
                <ListItem alignItems='center'><GraphQLErrors error={error!} refetch={refetch} /></ListItem>
            </List>
        )
    }
    if (loading && firstLoad) {
        return (
            <List className={classes.list}>
                <ListItem><Grid container={true} justify='center'><CircularProgress color='primary' size={48} /></Grid></ListItem>
            </List>
        )
    }

    return (
        <List className={classes.list}>
            {notifications.map((notification, i) => (
                <NotificationItem
                    key={i}
                    notification={notification}
                    onClick={handlerRedirect(notification, toggleSideBar)}
                    onMarkAsView={handlerMarkNotificationAsRead(notification)}
                    onDelete={handlerDeleteNofification(notification)}
                />
            ))}
            {!allNotificationsLoaded && (
                <Box className={classes.loadMoreBox}>
                    {loading
                        ? <CircularProgress color='primary' size={48} />
                        : (
                            <Button variant='contained' color='default' onClick={loadMore}>
                                <Typography><Trans>Load more</Trans></Typography>
                            </Button>
                        )
                    }
                </Box>
            )}
        </List>
    )
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    list: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
        overflowY: 'scroll',
    },
    loadMoreBox: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
}))

const useNotifications = ({unreadNotifications = false, pageSize: initialPageSize}: {unreadNotifications?: boolean, pageSize?: number}) => {
    const pageSize = initialPageSize || 5
    const [page, setPage] = useState(0)
    const router = useRouter()
    const [markNotificationAsRead] = useMarkNotificationAsReadMutation({
        refetchQueries: [
            {query: GetNotificationsCountDocument},
            {query: GetNotificationsDocument},
        ],
    })
    const [markAllNotificationsAsRead] = useMarkAllNotificationsAsReadMutation({
        refetchQueries: [
            {query: GetNotificationsCountDocument},
            {query: GetNotificationsDocument},
        ],
    })
    const [deleteNotification] = useDeleteNotificationMutation({
        refetchQueries: [
            {query: GetNotificationsCountDocument},
            {query: GetNotificationsDocument},
        ],
    })
    const [notifications, setNotifications] = useState<NotificationFragment[]>([])
    const [firstLoad, setFirstLoad] = useState(true)
    const [initialFetch, {data, loading, error, refetch, fetchMore}] = useGetNotificationsLazyQuery({
        onCompleted(data) {
            if (!data.notifications.items) return
            appendNotifications(data.notifications.items)
            if (firstLoad) setFirstLoad(false)
        },
    })
    const allNotificationsLoaded = data?.notifications.count === notifications.length

    const appendNotifications = (items: NotificationFragment[]) => {
        const filteredData = notifications.filter(notification => !items.some(item => item.id === notification.id))
        setNotifications([...items, ...filteredData].sort((a, b) => dayjs(b.created).diff(dayjs(a.created))))
    }

    useEffect(() => {
        initialFetch({variables: {options: {page: 0, pageSize, isReadBool: unreadNotifications ? ['false'] : undefined}}})
    }, [])

    useEffect(() => {
        const fetch = async () => {
            if (!fetchMore) return
            const {data} = await fetchMore({variables: {options: {page, pageSize, isReadBool: unreadNotifications ? ['false'] : undefined}}})
            if (data?.notifications.items.length > 0) {
                appendNotifications(data.notifications.items)
            }
        }
        if (!loading && !firstLoad) fetch()
    }, [page])

    const handlerMarkNotificationAsRead = (notification: NotificationFragment) => async () => {
        const {data} = await markNotificationAsRead({variables: {id: notification.id}})
        if (!data?.markNotificationAsRead?.ok) return
        const newNotification = notifications.find(({id}) => id === notification.id)
        if (newNotification) {
            appendNotifications([{...newNotification, isRead: true}])
        }
    }

    const handlerMarkAllNotificationsAsRead = () => async () => {
        const {data} = await markAllNotificationsAsRead()
        if (data?.markAllNotificationsAsRead?.ok) {
            setNotifications(state => [...state.map(({isRead: _, ...rest}) => ({...rest, isRead: true}))])
        }
    }

    const handlerDeleteNofification = (notification: NotificationFragment) => async () => {
        const {data} = await deleteNotification({variables: {id: notification.id}})
        if (!data?.deleteNotification?.ok) return
        setNotifications(prev => prev.filter(({id}) => id !== notification.id))
    }

    const handlerRedirect = (notification: NotificationFragment, toggleSideBar: () => void) => async () => {
        await handlerMarkNotificationAsRead(notification)()
        if (router.asPath === notification.actionPath) {
            await router.reload()
        } else {
            await router.push(notification.path)
        }
        toggleSideBar()
    }

    const loadMore = () => setPage(prev => prev + 1)

    return {
        handlerMarkNotificationAsRead,
        handlerMarkAllNotificationsAsRead,
        handlerDeleteNofification,
        handlerRedirect,
        error,
        loading,
        loadMore,
        refetch,
        firstLoad,
        allNotificationsLoaded,
        notifications,
    }
}
