import {t} from '@lingui/macro'
import {
    Box,
    createStyles,
    Drawer,
    FormControl,
    FormControlLabel,
    IconButton,
    makeStyles,
    Switch,
    Theme,
    Typography,
} from '@material-ui/core'
import {CloseRounded} from '@material-ui/icons'
import {ConfirmAction} from 'components/confirm-action'
import {NotificationSidebar} from 'components/notifications/notification-sidebar'
import {useUser} from 'contexts/user'
import {useToggle} from 'core/hooks'
import {useMobile} from 'core/hooks/use-mobile'
import {GetAuthUserDocument, useToggleEmailNotificationsMutation} from 'generated/graphql'
import {createContext, FC, useContext} from 'react'

type NotificationContextType = {
    toggleNotifications: () => void
    open: boolean
}

const NotificationContext = createContext<NotificationContextType>({
    toggleNotifications: () => {},
    open: false,
})

export const NotificationProvider: FC = ({children}) => {
    const {user} = useUser()
    const {open, toggle} = useToggle()
    const [toggleEmailNotifications] = useToggleEmailNotificationsMutation({refetchQueries: [{query: GetAuthUserDocument}]})
    const isMobile = useMobile()
    const classes = useStyles()
    const value = user?.emailNotifications

    const handleToggleEmailNotifications = async () => toggleEmailNotifications()

    if (!user) {
        return (
            <NotificationContext.Provider value={{toggleNotifications: toggle, open}}>
                {children}
            </NotificationContext.Provider>
        )
    }

    return (
        <NotificationContext.Provider value={{toggleNotifications: toggle, open}}>
            {children}
            <Drawer style={{display: 'relative'}} anchor='right' open={open} onClose={toggle} PaperProps={{style: {width: isMobile && '100%' || '350px'}}}>
                {isMobile && (
                    <Box position='absolute' top={0} right={0} zIndex={1}>
                        <IconButton color='secondary' onClick={toggle}><CloseRounded /></IconButton>
                    </Box>
                )}
                <Box width='100%' display='flex' flexDirection='column' minHeight='100%' maxHeight='100%' pt={isMobile && 2}>
                    <NotificationSidebar toggleSideBar={toggle} />
                    <Box className={classes.endBox}>
                        <ConfirmAction
                            title={t`Confirm`}
                            confirmText={user.emailNotifications
                                ? t`Are you sure you want stop receive email notifications?`
                                : t`Are you sure you want to receive email notifications?`
                            }
                            submitLabel={t`Confirm`}
                            submittingLabel={t`Submitting...`}
                            onSubmit={handleToggleEmailNotifications}>
                            <FormControl>
                                <FormControlLabel
                                    value={value}
                                    control={<Switch checked={value} />}
                                    color='primary'
                                    labelPlacement='start'
                                    label={<Typography className={classes.label}>{t`Receive notification to my e-mail`}</Typography>}
                                />
                            </FormControl>
                        </ConfirmAction>
                    </Box>
                </Box>
            </Drawer>
        </NotificationContext.Provider>
    )
}

export const useNotification = () => useContext(NotificationContext)

const useStyles = makeStyles<Theme>(theme => createStyles({
    endBox: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        alignSelf: 'center',
        width: '100%',
        padding: theme.spacing(2),
    },
    label: {
        color: 'white',
    },
}))
