// @ts-nocheck
/* eslint-disable import/no-named-default */
import {SvgIcon, SvgIconProps} from '@material-ui/core'
import {ExitToApp} from '@material-ui/icons'
import {FC} from 'react'

import {default as AccountIconSVG} from './assets/account-icon.svg'
import {default as ActivityIconSVG} from './assets/activity-icon.svg'
import {default as AdminIconSVG} from './assets/admin-icon.svg'
import {default as AnalyticsIconSVG} from './assets/analytics-icon.svg'
import {default as BellIconSVG} from './assets/bell-icon.svg'
import {default as ContactIconSVG} from './assets/contact-icon.svg'
import {default as HelpIconSVG} from './assets/help-icon.svg'
import {default as QuoteIconSVG} from './assets/quote-icon.svg'
import {default as SaleIconSVG} from './assets/sale-icon.svg'
import {default as SearchIconSVG} from './assets/search-icon.svg'
import {default as UserIconSVG} from './assets/user-icon.svg'

export const AccountIcon: FC<SvgIconProps> = props => <SvgIcon component={AccountIconSVG} {...props} />
export const ActivityIcon: FC<SvgIconProps> = props => <SvgIcon component={ActivityIconSVG} {...props} />
export const AdminIcon: FC<SvgIconProps> = props => <SvgIcon component={AdminIconSVG} {...props} />
export const AnalyticsIcon: FC<SvgIconProps> = props => <SvgIcon component={AnalyticsIconSVG} {...props} />
export const ContactIcon: FC<SvgIconProps> = props => <SvgIcon component={ContactIconSVG} {...props} />
export const HelpIcon: FC<SvgIconProps> = props => <SvgIcon component={HelpIconSVG} {...props} />
export const QuoteIcon: FC<SvgIconProps> = props => <SvgIcon component={QuoteIconSVG} {...props} />
export const SearchIcon: FC<SvgIconProps> = props => <SvgIcon component={SearchIconSVG} {...props} />
export const UserIcon: FC<SvgIconProps> = props => <SvgIcon component={UserIconSVG} {...props} />
export const LogoutIcon: FC<SvgIconProps> = props => <SvgIcon component={ExitToApp} {...props} />
export const SaleIcon: FC<SvgIconProps> = props => <SvgIcon component={SaleIconSVG} {...props} />
export const BellIcon: FC<SvgIconProps> = props => <SvgIcon component={BellIconSVG} {...props} viewBox='0 0 19 21' />
