import {Trans} from '@lingui/macro'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grow, useTheme} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {useRouter} from 'next/router'
import {OptionsObject, useSnackbar} from 'notistack'
import React, {FC, forwardRef, useState} from 'react'

interface ConfirmActionProps {
    children: React.ReactElement
    title: string
    confirmText: string
    successText?: string
    submitLabel: string
    submittingLabel: string
    redirectUrl?: string
    severity?: 'success' | 'error' | 'warning' | 'info'
    onSubmit(): void
    onCancel?: () => void
    snackbarOptions?: OptionsObject
}

export const ConfirmAction: FC<ConfirmActionProps> = forwardRef<HTMLLIElement, ConfirmActionProps>(({
    children,
    title,
    confirmText,
    successText,
    submitLabel,
    submittingLabel,
    redirectUrl,
    severity,
    onSubmit,
    onCancel,
    snackbarOptions,
}, ref) => {
    const router = useRouter()
    const classes = useStyles()
    const theme = useTheme()
    const {enqueueSnackbar} = useSnackbar()

    const [open, setOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const handleSubmit = async () => {
        setLoading(true)
        await onSubmit()
        setOpen(false)
        if (successText) enqueueSnackbar(successText, {...snackbarOptions, variant: 'success'})
        if (redirectUrl) router.push(redirectUrl)
        setLoading(false)
    }

    const borderColor = !severity ? theme.palette.grey[300] : theme.palette[severity].main

    return (
        <>
            {React.cloneElement(children, {
                ref,
                onClick: () => setOpen(true),
            })}
            <Dialog
                open={open}
                keepMounted={false}
                TransitionComponent={Grow}
                // TO-DO: Remove these styles when AsyncTable no longer requires a custom theme.
                PaperProps={{style: {border: 'none'}}}>
                <DialogTitle style={{borderTop: `4px solid ${borderColor}`}}>{title}</DialogTitle>
                <DialogContent className={classes.content}>
                    {confirmText}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpen(false)
                            onCancel?.()
                        }}
                        disabled={loading}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button onClick={handleSubmit} color='secondary' variant='contained' disabled={loading}>
                        {loading ? submittingLabel : submitLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
})

const useStyles = makeStyles((theme: Theme) => createStyles({
    content: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}))
