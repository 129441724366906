import {Trans} from '@lingui/macro'
import {
    Avatar,
    Badge,
    Box,
    Button,
    createStyles,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
} from '@material-ui/core'
import {MoreVert} from '@material-ui/icons'
import {useToggle} from 'core/hooks'
import {NotificationFragment, NotificationsNotificationIconChoices} from 'generated/graphql'
import {humanize} from 'i18n'
import {BellIcon, QuoteIcon, SaleIcon} from 'icons'
import Link from 'next/link'
import {FC, useRef} from 'react'

const ICONS: {[key in NotificationsNotificationIconChoices]?: React.ReactNode} = {
    [NotificationsNotificationIconChoices.Default]: <BellIcon />,
    [NotificationsNotificationIconChoices.News]: <BellIcon />,
    [NotificationsNotificationIconChoices.PortalSappio]: <BellIcon />,
    [NotificationsNotificationIconChoices.Quote]: <QuoteIcon />,
    [NotificationsNotificationIconChoices.Sale]: <SaleIcon />,
}

type NotificationItemProps = {
    notification: NotificationFragment
    onClick: () => void
    onMarkAsView: () => void
    onDelete: () => void
}

export const NotificationItem: FC<NotificationItemProps> = ({notification, onClick, onMarkAsView, onDelete}) => {
    const classes = useStyles()
    const anchorEl = useRef<HTMLButtonElement>(null)
    const {open, toggle} = useToggle()

    const menuItemHandler = (func: Function) => () => {
        toggle()
        func()
    }

    return (
        <>
            <ListItem button={true} onClick={onClick}>
                <ListItemIcon>
                    {!notification.isRead && (
                        <Badge variant='dot' color='secondary' showZero={true} anchorOrigin={{horizontal: 'left', vertical: 'top'}}>
                            <Box width='10px' />
                        </Badge>
                    ) || <Box width='10px' />}
                </ListItemIcon>
                <ListItemIcon>
                    <Avatar variant='rounded' className={classes.icon}>
                        {notification.icon && ICONS[notification.icon] || ICONS.DEFAULT}
                    </Avatar>
                </ListItemIcon>
                <ListItemText
                    primary={<Box component='span' dangerouslySetInnerHTML={{__html: notification.content}} />}
                    secondary={(
                        <Box component='span' display='flex' flexDirection='column' gridRowGap={4}>
                            {humanize(notification.created)}
                            {notification.actionPath && notification.actionLabel && (
                                <Link href={notification.actionPath} passHref={true}>
                                    <Button variant='contained' color='primary'>{notification.actionLabel}</Button>
                                </Link>
                            )}
                        </Box>
                    )}
                    secondaryTypographyProps={{className: classes.secondary}}
                />
                <ListItemSecondaryAction onClick={toggle}>
                    <IconButton
                        ref={anchorEl}
                        edge='end'>
                        <MoreVert />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Menu
                anchorEl={anchorEl.current}
                open={open}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
                onClose={toggle}>
                <MenuItem onClick={menuItemHandler(onClick)}>
                    <Trans>Go to</Trans>
                </MenuItem>
                <MenuItem onClick={menuItemHandler(onMarkAsView)}>
                    <Trans>Mark as view</Trans>
                </MenuItem>
                <MenuItem onClick={menuItemHandler(onDelete)}>
                    <Trans>Delete notification</Trans>
                </MenuItem>
            </Menu>
        </>
    )
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    tabRoot: {
        textTransform: 'none',
    },
    loadMoreBox: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    icon: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.primary.main,
    },
    secondary: {
        color: theme.palette.secondary.main,
    },
}))

